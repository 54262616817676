import React, { useEffect } from 'react'
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom'
import Banner from './Banner/Banner'

const DopDetailPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Navbar />
            <Banner />
        </>
    )
}

export default DopDetailPage
