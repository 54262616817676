import React from 'react'
import "./info.scss"
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'

const Information = () => {
    const { t } = useTranslation()
    
    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{margin: "0"}}>
                    <h2>{t('Dop-detail4')} <span></span></h2>
                    <p>{t('Dop-detail5')}</p>
                    <p>{t('Dop-detail6')}</p>
                </div>
                <div className="img-wrapper">
                    <img src="\assets\detail-page\projectinfo1dop.svg" alt="img" className='img-fluid' />
                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail7')} <span></span></h2>
                    {/* <p>Aut consequatur exercitationem aut error quia aut voluptate sunt non quaerat magnam. Cum enim dolorem sit voluptate voluptatem a optio quos hic dicta dolore aut quia galisum eum eius libero. Nam atque quia ut autem fugit hic consequatur illum qui consequatur eveniet in galisum aliquam. In accusamus fuga quo iusto Quis sit aliquam rerum.</p> */}
                </div>
                {/* <div className="video-part">
                    <Player
                        playsInline
                        poster="\assets\detail-page\videoframedop.png"
                        src=""
                    />
                    <img src="\assets\detail-page\videoframedop.png" alt="img" className='img-fluid' />
                </div> */}
                <div className="first-para">
                    {/* <p>Hum enim dolorem sit voluptate voluptatem a optio quos hic dicta dolore aut quia galisum eum eius libero. Nam atque quia ut autem fugit hic consequatur illum qui consequatur eveniet in galisum aliquam. In accusamus fuga quo iusto Quis sit aliquam rerum.</p> */}
                    <ul>
                        <li><span className='bold-text'>{t('Dop-detail8')}</span> {t('Dop-detail9')}</li>
                        <li><span className='bold-text'>{t('Dop-detail10')}</span> {t('Dop-detail11')}</li>
                        <li><span className='bold-text'>{t('Dop-detail12')}</span> {t('Dop-detail13')}</li>
                        <li><span className='bold-text'>{t('Dop-detail14')}</span> {t('Dop-detail15')}</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail16')} <span></span></h2>
                    <p>{t('Dop-detail17')}</p>
                    <p>{t('Dop-detail18')}</p>
                    <p>{t('Dop-detail19')}</p>
                    <p>{t('Dop-detail20')}</p>
                    <p>{t('Dop-detail21')}</p>
                    {/* <ul>
                        <li>Hum enim dolorem sit voluptate voluptatem a optio quos hic dicta dolore aut quia galisum eum eius libero. Nam atque quia ut autem fugit hic consequatur illum qui consequatur.</li>
                        <li>Qui atque voluptatem cum commodi unde aut culpa commodi et molestias libero id vero ducimus aut omnis sunt. Et repellat sunt aut perspiciatis expedita est quos obcaecati.</li>
                        <li>Nam explicabo Quis non unde alias sed voluptate possimus! Id vitae dolorum sed neque asperiores At impedit quia aut pariatur voluptas id omnis esse ut incidunt quod.</li>
                        <li>Hum enim dolorem sit voluptate voluptatem a optio quos hic dicta dolore aut quia galisum eum eius libero. Nam atque quia ut autem fugit hic consequatur illum qui consequatur.</li>
                        <li>Qui atque voluptatem cum commodi unde aut culpa commodi et molestias libero id vero ducimus aut omnis sunt. Et repellat sunt aut perspiciatis expedita est quos obcaecati.</li>
                        <li>Nam explicabo Quis non unde alias sed voluptate possimus! Id vitae dolorum sed neque asperiores At impedit quia aut pariatur voluptas id omnis esse ut incidunt quod.</li>
                    </ul> */}
                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail22')} <span></span></h2>
                    {/* <p>Lorem ipsum dolor sit amet. Aut voluptatem explicabo aut fuga impedit nam nesciunt nesciunt ut quisquam recusandae. Ea porro voluptatem sed expedita earum ut labore architecto qui earum corporis.</p> */}
                    <div className="team-gems">
                        <div className="single-team">
                            <img src="\assets\ourteam\waleed.png" alt="img" className='img-fluid' />
                            <h6>{t('Dop-detail23')}</h6>
                            <p>{t('Dop-detail24')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\ourteam\kohji.png" alt="img" className='img-fluid' />
                            <h6>{t('Dop-detail25')}</h6>
                            <p>{t('Dop-detail26')}</p>
                        </div>
                        {/* <div className="single-team">
                            <img src="\assets\ourteam\mike.png" alt="img" className='img-fluid' />
                            <h6>Mike Coral, Business Development </h6>
                            <p>Founding member of major projects like Chilliz and tomi. Leads listings, partnerships, and business development efforts.</p>
                        </div> */}
                        <div className="single-team">
                            <img src="\assets\ourteam\matan.png" alt="img" className='img-fluid' />
                            <h6>{t('Dop-detail27')}</h6>
                            <p>{t('Dop-detail28')}</p>
                        </div>
                        {/* <div className="single-team">
                            <img src="\assets\detail-page\team\t5.svg" alt="img" className='img-fluid' />
                            <h6>Victor Lopez</h6>
                            <p>Nam voluptatibus quae qui minima incidunt et autem esse! Est vitae provident ut impedit repellendus et tempore voluptatibus.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\detail-page\team\t6.svg" alt="img" className='img-fluid' />
                            <h6>Anna King</h6>
                            <p>Nam voluptatibus quae qui minima incidunt et autem esse! Est vitae provident ut impedit repellendus et tempore voluptatibus.</p>
                        </div> */}
                    </div>

                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail29')} <span></span></h2>
                    <p>{t('Dop-detail30')}</p>
                    <div className="partners-content">
                        <img src="\assets\detail-page\partner\bitcoin.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\hacken.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\chain.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\zkme.svg" alt="img" className='img-fluid' />
                    </div>
                </div>
                <div className="first-para">
                    {/* <h2>What are token metrics of DOP? <span></span></h2> */}
                    <h2>{t('Dop-detail31')} <span></span></h2>
                    {/* <p>Est omnis velit et enim quia et molestiae dolorem in impedit temporibus. Non numquam odit in consequatur fugit ea dolor doloremque sit modi numquam. Aut dolores delectus qui minima quod aut maiores repellendus et expedita quaerat. Et ducimus praesentium ea internos natus ut provident totam.</p> */}
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img">
                            <img src="\assets\detail-page\dop-tokenomics.png" alt="img" className='img-fluid' />
                        </div>
                        {/* <div className="right-content">
                            <div className="text">
                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" fill="#009482" />
                                </svg>Early Contributors</h6>
                                <p>20%</p>
                            </div>
                            <div className="text">
                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" fill="#00AE99" />
                                </svg>Investors</h6>
                                <p>14%</p>
                            </div>
                            <div className="text">
                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" fill="#00C9B1" />
                                </svg>Mysten Labs Treasury</h6>
                                <p>10%</p>
                            </div>
                            <div className="text">
                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" fill="#00E4C8" />
                                </svg>Public Sale</h6>
                                <p>6%</p>
                            </div>
                            <div className="text">
                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" fill="#53FFEA" />
                                </svg>Community Reserve</h6>
                                <p>50%</p>
                            </div>
                        </div> */}
                    </div>

                </div>
            </section>
        </>
    )
}

export default Information
