import React, { useState } from 'react'
import "./media.scss"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

const Media = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleShow = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false)};
  const handleClose1 = () => setShow1(false);

  const images = [
    'assets/detail-page/incteam/media/1.jpg',
    'assets/detail-page/incteam/media/2.jpg',
    'assets/detail-page/incteam/media/3.jpg',
  ];

  return (
    <>
      <section className="media-section">
        <div className="first-para" style={{ margin: "0" }}>
          <h2>{t('Gems-detailt44')} <span></span></h2>
        </div>
        <div className="bottom-gallery">
          <div onClick={() => {setShow1(true)}} className="single-gallery" style={{cursor: "pointer"}}>
            <img src="\assets\detail-page\incteam\media\video-preview.jpg" alt="img" className='img-fluid' />
            <img src="\play-btn.svg" alt="img" className='img-fluid play-btn' />
          </div>
          {images.map((image, index) => (
            <div className="single-gallery" key={index}>
              <img
                src={image}
                alt={`img-${index}`}
                className="img-fluid"
                onClick={() => handleShow(image)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          ))}

        </div>
        {/* <div className="content-unavailable">
          <img src="\assets\detail-page\contentunavail.svg" alt="img" className='img-fluid' />
          <h6>Content Unavailable</h6>
        </div> */}
          <Modal className='image-preview' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='p-0'>
          <img src={selectedImage} alt="Selected" className="img-fluid w-100" />
        </Modal.Body>
      </Modal>


      <Modal className='image-preview' show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='p-0'>
          <video poster='\assets\detail-page\incteam\media\video-preview.jpg' controls style={{ width: "100%", height: "100%" }}>
            <source src="https://res.cloudinary.com/drt6vurtt/video/upload/v1718037503/incentiv/incentiv-video_isxdf8.mp4" type="video/mp4" />
            <source src="https://res.cloudinary.com/drt6vurtt/video/upload/v1718037503/incentiv/incentiv-video_isxdf8.mp4" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
      </Modal>
      </section>


    


    </>
  )
}

export default Media
