import React from 'react'
import "./where.scss"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Where = () => {
    const { t } = useTranslation()

    return (
        <>
            <section className="where-section">
                <div className="custom-container">
                    <div className="parent">
                        <div className="right-side">
                            <div className="wrapper-img">
                                <img src="\assets\landing\gemswhereproject.svg" alt="img" className='img-fluid' />
                            </div>
                        </div>
                        <div className="left-side">
                            <div className="main-content">
                            <h6>{t('Where-t1')}</h6>
                                    <p>{t('Where-t2')}</p>
                                <div className="twice-btns">
                                    <Link to="/signupforproject" className="btn-common">{t('Where-btn1')}</Link>
                                    {/* <Link to="/signupforleader" className="btn-black">Sign up as Leader</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Where
