// import logo from './logo.svg';
import "../../App.scss";
import React, { useState, useEffect, useRef } from "react";
import Banner from "./main-banner/Banner.js";
import Navbar from "./header/Navbar";
import Result from "./Results/Result.js";
import Era from "./NewEra/Era.js";
import Twice from "./Twice/Twice.js";
import Process from "./Process/Process.js";
import Where from "./Where/Where.js";
import How from "./HowItWorks/How.js";
import Stats from "./GemsTokenStats/Stats.js";



function Landing({gemsData}) {
 

  useEffect(() => {
    document.body.classList.add('landing-body-class');

    return () => {
      document.body.classList.remove('landing-body-class');
    };
  }, []);

  return (
    <>
      <div className="set-parent-bannertwice">
        <img src="\assets\landing\banner-bg.png" alt="img" className="img-fluid banner-bg" />
        <img src="\assets\landing\banner-bg-mbl.png" alt="img" className="img-fluid banner-bg-mbll d-none" />
        <Banner
        />
        <Process />
      </div>
      <div>
        <Result />
      </div>
      <Era />
      <Stats gemsData={gemsData} />
      <Twice />
      {/* <How /> */}
      <Where />
    </>
  );
}

export default Landing;
