import React, { useEffect, useState } from "react";
import "./process.scss";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";


function SampleNextArrow(props) {
  const { className, onClick, hideArrow } = props;
  return !hideArrow ? (
    <div className={className} onClick={onClick}>
      <img
        src="/assets/landing/carousel-arrow-next.svg"
        alt="Next"
        className="img-fluid"
      />
    </div>
  ) : null; // Return null when you want to hide the arrow
}

function SamplePrevArrow(props) {
  const { className, onClick, hideArrow } = props;
  return !hideArrow ? (
    <div className={className} onClick={onClick}>
      <img
        src="/assets/landing/carousel-arrow-prev.svg"
        alt="Prev"
        className="img-fluid"
      />
    </div>
  ) : null; // Return null when you want to hide the arrow
}

const Process = () => {
  const { t } = useTranslation();

  const [timeshow, setTimeshow] = useState(false);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [day1, setDay1] = useState(0);
  const [hour1, setHour1] = useState(0);
  const [min1, setMin1] = useState(0);
  const [sec1, setSec1] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const time = 1726628400000;
      const diff = time - now;
      if (diff <= 0) {
        clearInterval(interval);
        setTimeshow(true);
        return;
      }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const secs = Math.floor((diff % (1000 * 60)) / 1000);
      setDay(days);
      setHour(hours);
      setMin(mins);
      setSec(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const time = 1727280000000;
      const diff = time - now;
      if (diff <= 0) {
        clearInterval(interval);
        setTimeshow(true);
        return;
      }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const secs = Math.floor((diff % (1000 * 60)) / 1000);
      setDay1(days);
      setHour1(hours);
      setMin1(mins);
      setSec1(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  // leia timer here ...........................

  const [timeshowl, setTimeshowl] = useState(false);
  const [dayl, setDayl] = useState(0);
  const [hourl, setHourl] = useState(0);
  const [minl, setMinl] = useState(0);
  const [secl, setSecl] = useState(0);
  const [dayl1, setDayl1] = useState(0);
  const [hourl1, setHourl1] = useState(0);
  const [minl1, setMinl1] = useState(0);
  const [secl1, setSecl1] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const time = 1726567200000;
      const diff = time - now;
      if (diff <= 0) {
        clearInterval(interval);
        setTimeshowl(true);
        return;
      }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const secs = Math.floor((diff % (1000 * 60)) / 1000);
      setDayl(days);
      setHourl(hours);
      setMinl(mins);
      setSecl(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const time = 1727110800000;
      const diff = time - now;
      if (diff <= 0) {
        clearInterval(interval);
        setTimeshowl(true);
        return;
      }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const secs = Math.floor((diff % (1000 * 60)) / 1000);
      setDayl1(days);
      setHourl1(hours);
      setMinl1(mins);
      setSecl1(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, []);





  // end.................................

  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 1.2;

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: (
      <SampleNextArrow hideArrow={currentSlide >= totalSlides - 1} />
    ),
    prevArrow: <SamplePrevArrow hideArrow={currentSlide === 0} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="process-projects">
        <div className="custom-container padd-right-sm">
          {/* <div className="main-heading">
            <h6>{t('Process-t1')}</h6>
          </div> */}
          <div className="bottom-cards">
            <div className="slider">
              <Slider {...settings}>
                <div className="single-card order1inmobile activee">
                  <img src="\assets\landing\border-card-img.png" alt="img" className="img-fluid border-card-img" />
                  <Link to="/olympus">
                    <div className="upper-twice">
                      <div className="main-img">
                        <img
                          src="\assets\landing\omp-coin.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="right-text">
                        <h6>Olympus AI</h6>
                        {
                          !timeshow ? <p>{t('Process-t13')}</p> : <p>{t('Process-t10')}</p>
                        }
                        {!timeshow ?
                          <h5>{day ? day : 0}D : {hour ? hour : 0}H : {min ? min : 0}M : {sec ? sec : 0}S</h5>
                          :
                          <h5>{day1 ? day1 : 0}D : {hour1 ? hour1 : 0}H : {min1 ? min1 : 0}M : {sec1 ? sec1 : 0}S</h5>
                        }
                      </div>
                    </div>
                    <div className="bottom-twice">
                      <div className="inside-new-inline">
                        <div className="inner-text">
                          <p>{t('Process-t4')}</p>
                          <h6 style={{ textAlign: "start" }}>2</h6>
                        </div>
                        <div className="inner-text">
                          <p>{t('Process-t3')}</p>
                          <h6>$0.104</h6>
                        </div>
                      </div>
                      <div className="bottom-raised">
                        <p>TOTAL RAISED</p>
                        <h6>--</h6>
                      </div>
                    </div>
                  </Link>
                  {
                    !timeshow ? <Link
                      to="/olympus"
                      className="btn-detail view-project-btn">
                      {t('Process-t7')}
                    </Link> : <a
                      href="https://olympusai.gems.vip"
                      target="_blank"
                      className="btn-detail">
                      {t('Btn-buynow')}
                    </a>

                  }
                </div>
                <div className="single-card order1inmobile activee">
                  <img src="\assets\landing\border-card-img.png" alt="img" className="img-fluid border-card-img" />
                  <Link to="/leia">
                    <div className="upper-twice">
                      <div className="main-img">
                        <img
                          src="\assets\landing\leia-card.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="right-text">
                        <h6>LEIA</h6>
                        {
                          !timeshowl ? <p>{t('Process-t13')}</p> : <p>{t('Process-t10')}</p>
                        }
                        {!timeshowl ?
                          <h5>{dayl ? dayl : 0}D : {hourl ? hourl : 0}H : {minl ? minl : 0}M : {secl ? secl : 0}S</h5>
                          :
                          <h5>{dayl1 ? dayl1 : 0}D : {hourl1 ? hourl1 : 0}H : {minl1 ? minl1 : 0}M : {secl1 ? secl1 : 0}S</h5>
                        }
                      </div>
                    </div>
                    <div className="bottom-twice">
                      <div className="inside-new-inline">
                        <div className="inner-text">
                          <p>{t('Process-t4')}</p>
                          <h6 style={{ textAlign: "start" }}>2</h6>
                        </div>
                        <div className="inner-text">
                          <p>{t('Process-t3')}</p>
                          <h6>$0.000103</h6>
                        </div>
                      </div>
                      <div className="bottom-raised">
                        <p>TOTAL RAISED</p>
                        <h6>--</h6>
                      </div>
                    </div>
                  </Link>
                  {
                    !timeshowl ? <Link
                      to="/leia"
                      className="btn-detail view-project-btn">
                      {t('Process-t7')}
                    </Link> : <a
                      href="https://leia.gems.vip"
                      target="_blank"
                      className="btn-detail">
                      {t('Btn-buynow')}
                    </a>
                  }
                </div>
                <div className="single-card order1inmobile">
                  <Link to="/incentiv">
                    <div className="upper-twice">
                      <div className="main-img">
                        <img
                          src="\assets\landing\incentive-img.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="right-text">
                        <h6>Incentiv</h6>
                        {/* {
                          !timeshow ? <p>{t('Process-t13')}</p> : <p>{t('Process-t10')}</p>
                        } */}
                        <p>{t('Process-t10')}</p>

                        <h5 style={{ color: "#FE0000" }}>{t('Dop-detail3')}</h5>

                        {/* {!timeshow ?
                          <h5>{day ? day : 0}D : {hour ? hour : 0}H : {min ? min : 0}M : {sec ? sec : 0}S</h5>
                          :
                          <h5>{day1 ? day1 : 0}D : {hour1 ? hour1 : 0}H : {min1 ? min1 : 0}M : {sec1 ? sec1 : 0}S</h5>
                        } */}
                      </div>
                    </div>
                    <div className="bottom-twice">
                      <div className="inside-new-inline">
                        <div className="inner-text">
                          <p>{t('Process-t4')}</p>
                          <h6 style={{ textAlign: "start" }}>--</h6>
                        </div>
                        <div className="inner-text">
                          <p>{t('Process-t3')}</p>
                          <h6>--</h6>
                        </div>
                      </div>
                      <div className="bottom-raised">
                        <p>TOTAL RAISED</p>
                        <h6>$25,000,000+</h6>
                      </div>
                    </div>
                  </Link>
                  {/* {
                    !timeshow ? <Link
                      to="/incentiv"
                      className="btn-detail">
                      {t('Process-t7')}
                    </Link> : <a
                      href="https://privatesale.gems.vip/"
                      target="_blank"
                      className="btn-detail">
                      {t('Btn-buynow')}
                    </a>
                  } */}
                  <Link
                    to="/incentiv"
                    className="btn-detail view-project-btn"
                  >
                    {t('Process-t7')}
                  </Link>
                </div>
                <div className="single-card order1inmobile">
                  <Link to="/gemsdetail">
                    <div className="upper-twice">
                      <div className="main-img">
                        <img
                          src="\assets\landing\gems-main-img.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="right-text">
                        <h6>GEMS</h6>
                        <p>{t('Process-t10')}</p>
                        <h5 style={{ color: "#FE0000" }}>{t('Dop-detail3')}</h5>
                      </div>
                    </div>
                    <div className="bottom-twice" style={{ justifyContent: "center" }}>
                      <div className="inside-new-inline">
                        <div className="inner-text">
                          <p>{t('Process-t4')}</p>
                          <h6 style={{ textAlign: "start" }}>--</h6>
                        </div>
                        <div className="inner-text">
                          <p>{t('Process-t3')}</p>
                          <h6>--</h6>
                        </div>
                      </div>
                      <div className="bottom-raised">
                        <p>{t('Totalrasied')}</p>
                        <h6>$10,000,000</h6>
                      </div>

                    </div>
                  </Link>
                  <Link
                    to="/gemsdetail"
                    className="btn-detail view-project-btn">
                    {t('Process-t7')}
                  </Link>
                </div>
                <div className="single-card order2inmobile">
                  <Link to="/dopdetail">
                    <div className="upper-twice">
                      <div className="main-img">
                        <img
                          src="\assets\landing\dop-main-img.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="right-text">
                        <h6>DOP</h6>
                        <p>{t('Process-t10')}</p>
                        <h5 style={{ color: "#FE0000" }}>{t('Dop-detail3')}</h5>
                      </div>
                    </div>
                    <div className="bottom-twice" style={{ justifyContent: "center" }}>
                      <div className="inside-new-inline">
                        <div className="inner-text">
                          <p>{t('Process-t4')}</p>
                          <h6 style={{ textAlign: "start" }}>--</h6>
                        </div>
                        <div className="inner-text">
                          <p>{t('Process-t3')}</p>
                          <h6>--</h6>
                        </div>
                      </div>
                      <div className="bottom-raised">
                        <p>{t('Totalrasied')}</p>
                        <h6>$160,000,000</h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/dopdetail" className="btn-detail view-project-btn">
                    {t('Process-t7')}
                  </Link>
                </div>
                {/* <div
                  className="single-card order3inmobile"
                  style={{ cursor: "not-allowed", pointerEvents: "none" }}>
                  <img
                    src="\assets\landing\coming-soon.svg"
                    alt="img"
                    className="img-fluid h-100 w-100"
                  />
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Process;
