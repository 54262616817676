import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Navbar1 = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="navbar-secondary">
        <div className="custom-container">
          <div className="parent">
            <div className="logo">
              <Link to="/">
                <img src="\gems-logo.svg" alt="img" className="img-fluid" />
              </Link>
            </div>
            <div className="right-side">
              <h6>
                <a href="https://leaders.gems.vip/" target="_blank">
                  {t("Navbar-t1")}
                </a>
              </h6>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar1;
