import React from "react";
import { Link } from "react-router-dom";
import "./how.scss"
import { useTranslation } from 'react-i18next'

const How = () => {
  const { t } = useTranslation()

  return (
    <>
      <section className="how-projects">
        <img
          src="\assets\shadows\bottom-shadow-mobilee.png"
          alt="img"
          className="img-fluid bottom-shadow-mobile d-none"
        />
        <img
          src="\assets\shadows\project-bg-shadow.png"
          alt="img"
          className="img-fluid project-bg-shadow"
        />
        <div className="custom-container">
          <div className="howitworks">
            <div className="left-content">
              <h6>{t('How-t1')}</h6>
              <p>
              {t('How-t2')}
              </p>
            </div>
            <div className="right-content">
              <div className="inner-card">
                <div className="circle-dig">
                  <p>1</p>
                </div>
                <h6>   {t('How-t3')}</h6>
              </div>
              <div className="inner-card">
                <div className="circle-dig">
                  <p>2</p>
                </div>
                <h6>   {t('How-t4')}</h6>
              </div>
              <div className="inner-card">
                <div className="circle-dig">
                  <p>3</p>
                </div>
                <h6>   {t('How-t5')}</h6>
              </div>
              <div className="inner-card">
                <div className="circle-dig">
                  <p>4</p>
                </div>
                <h6>   {t('How-t6')} </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default How;
