import React, { useState } from 'react'
import "./info.scss"
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap';

const Information = () => {
    const { t } = useTranslation()

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{ margin: "0" }}>
                    <h2>{t('Dop-detail4')} <span></span></h2>
                    <p>Olympus AI is an innovative platform that integrates multiple leading AI models to create a workforce of AI “employees” capable of performing real-world tasks with unprecedented efficiency and accuracy. Olympus AI is a technological initiative that's set to reshape the way we think about work, productivity, and human-AI collaboration.</p>
                </div>
                <div className="first-para">
                    <h2>WHAT MAKES OLYMPUS AI UNIQUE <span></span></h2>
                    <ol>
                        <li><span>Real-World Task Execution:</span> Through its innovative blockchain technology, Olympus AI employees can perform tangible, real-world tasks. While AI employees cannot have a bank account, they can have a crypto wallet. By utilizing blockchain technology, we allow our AI employees to act on the users’ behalf, both in the digital and real-world. From making payments to ordering goods and services, its AI workforce doesn’t just advise - it actually performs the tasks you need done to bring your business forward.</li>
                        <li><span>Multi-Model Integration:</span> Olympus AI harnesses the power of +7 cutting-edge AI models, combining their strengths to create a versatile and robust AI workforce.</li>
                        <li><span>Collaborative AI Employees:</span> The AI employees can work together seamlessly, tackling complex projects that require diverse skill sets and interdisciplinary knowledge.</li>
                        <li><span>Scalable Solutions:</span> From small businesses to large enterprises, Olympus AI adapts to the users’ needs, providing tailored AI-powered workforce solutions.</li>
                        <li><span>Enhanced Human-AI Synergy:</span> Access a pool of incredibly talented and capable employees across various fields, at a fraction of the cost. The platform is democratizing the high-end workforce, allowing businesses of all sizes to leverage top-tier skills and expertise that were previously out of reach.</li>
                    </ol>
                </div>
                <div className="first-para">
                    <h2>Transforming Industries with AI-Powered Employees <span></span></h2>
                    <ul>
                        <li><span>Efficiency:</span> Reduce costs and increase output with 24/7 AI employees that never tire.</li>
                        <li><span>Innovation:</span> Unlock new possibilities by combining diverse AI capabilities in novel ways.</li>
                        <li><span>Adaptability:</span> Stay ahead of the curve with an AI workforce that continuously learns and evolves.</li>
                        <li><span>All-in-One Solution:</span> Say goodbye to juggling multiple AI platforms. Olympus AI offers a comprehensive ecosystem where all your AI needs are met in one place, streamlining your workflow and maximizing productivity.</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>The Future of Work is Here <span></span></h2>
                    <p>Olympus AI represents a significant leap forward in AI technology and its practical applications in the workplace. This groundbreaking platform has the potential to completely alter how businesses operate, by providing a versatile, highly skilled AI workforce at a fraction of traditional costs. </p>
                    <p>By investing in Olympus AI, you're supporting the development of a system that can dramatically enhance productivity, reduce operational expenses, and unlock new opportunities across industries.</p>
                </div>
                <div className="first-para">
                    <h2>OLYMPUS AI'S GO-TO-MARKET STRATEGY <span></span></h2>
                    <p>Olympus AI will drive user growth and platform adoption through a multi-faceted approach
                        combining community engagement, strategic partnerships, and performance marketing. By
                        building a strong community with AMAs, webinars, and product demos, collaborating with
                        leading AI and blockchain companies, and employing targeted digital marketing
                        campaigns, the platform will attract high-intent users. Incentive programs such as staking
                        rewards and a referral program will further boost engagement, while continuous innovation
                        and expansion of AI capabilities will ensure long-term relevance and scalability.</p>
                </div>
                <div className="first-para">
                    <h2>The Team <span></span></h2>
                    {/* <p>Lorem ipsum dolor sit amet. Aut voluptatem explicabo aut fuga impedit nam nesciunt nesciunt ut quisquam recusandae. Ea porro voluptatem sed expedita earum ut labore architecto qui earum corporis.</p> */}
                    <div className="team-gems">
                        <div className="single-team">
                            <img src="\assets\olympusteam\mor.jpg" alt="img" className='img-fluid' />
                            <h6>Mor Hogeg </h6>
                            <h5>General Manager</h5>
                            <p>A blockchain researcher and attorney with over seven years of experience, Mor has been deeply immersed in artificial intelligence technology for several years. Mor leverages this unique blend of expertise to guide Olympus AI's product development and strategic direction, steering the platform towards innovative AI solutions.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\olympusteam\lotem.jpg" alt="img" className='img-fluid' />
                            <h6>Lotem Elazar </h6>
                            <h5>Developer</h5>
                            <p>Lotem is a versatile AI prompt engineer and developer with deep expertise in React, Node.js, and MongoDB, and experience in creating scalable and efficient applications. With a growing interest and experience in Web3 technologies, Lotem is committed to staying current with emerging trends, continually sharpening his skill set to deliver innovative and future-ready solutions.</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\olympusteam\omri.jpg" alt="img" className='img-fluid' style={{objectPosition: "50%"}} />
                            <h6>Omri Bakish  </h6>
                            <h5>Developer</h5>
                            <p>A seasoned React and Node.js developer with 4 years of hands-on experience in building scalable applications using React, React Native, Node APIs, and Redux. Omri is a skilled prompt engineer who is passionate about continuous learning, always striving to refine skills and stay ahead of industry trends, ensuring the delivery of cutting-edge solution</p>
                        </div>
                        
                       
                   
                      
                    </div>

                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail31')} <span></span></h2>
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img">
                            <img src="\assets\detail-page\olympus-tokenomics.png" alt="img" className='img-fluid' />
                        </div>
                    </div>
                </div>
                <div className="first-para">
                    <h2>{t('Distandallocation')} <span></span></h2>
                    <div onClick={() => { setShow1(true) }} className="twice-tokenomics" style={{ cursor: "pointer" }}>
                        <div className="tokenomic-img">
                            <img src="\assets\detail-page\olympus-distribution.png" alt="img" className='img-fluid' />
                        </div>
                    </div>

                </div>


            </section>

            <Modal className='image-preview distribution-modl' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='p-0'>
                    <img src="\assets\detail-page\olympus-distribution.png" alt="Selected" className="img-fluid w-100" />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Information
