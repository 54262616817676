import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "../landing/header/Navbar";
import moment from "moment";
const BlogDetailBanner = ({ scrollToSecondSection, title, date }) => {
  const { t } = useTranslation();

  // const [showNav, setShowNav] = useState(true);

  // useEffect(() => {
  //   let timeoutId;

  //   const handleScroll = () => {
  //     setShowNav(true);
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       setShowNav(false);
  //     }, 3000);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //     clearTimeout(timeoutId);
  //   };
  // }, []);
  return (
    <>
      <Navbar />
      <section className="main-bannernew">
        <img
          src="https://res.cloudinary.com/drt6vurtt/image/upload/v1709191268/gems/banner-bg_kk3bqp.svg"
          alt="img"
          className="img-fluid banner-bg"
        />
        <div className="custom-container">
          <div className="main-contentss">
            <div className="faqsheads">
              <Link to="/blog">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17 12H7M7 12L11 16M7 12L11 8"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Back
              </Link>
              <h3>{title}</h3>
              <p className="green-text">
                {moment(date).format("MMMM DD, YYYY")}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetailBanner;
