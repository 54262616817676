import React, { useState } from 'react'
import "./media.scss"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

const Media = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleShow = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const images = [
    'assets/gemsteam/IMG_7205-Edit.JPG',
    'assets/gemsteam/IMG_7282.JPG',
  ];
  return (
    <>
      <section className="media-section">
        <div className="first-para" style={{ margin: "0" }}>
          <h2>{t('Gems-detailt44')} <span></span></h2>
        </div>
        <div className="bottom-gallery">
        {images.map((image, index) => (
            <div className="single-gallery" key={index}>
              <img
                src={image}
                alt={`img-${index}`}
                className="img-fluid"
                onClick={() => handleShow(image)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          ))}
          
        </div>
      </section>

      <Modal className='image-preview' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='p-0'>
          <img src={selectedImage} alt="Selected" className="img-fluid w-100" />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Media
