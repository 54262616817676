import React, { useEffect } from "react";
import "./career.scss";
import BannerApply from "./BannerApply";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {t} = useTranslation();
  return (
    <>
      <div className="wrapper-apply wrapper-career">
        <BannerApply />
        <section className="career-section">
          <div className="custom-container">
            <div className="parent">
              {/* <div className="single-card">
                <div className="left-content">
                  <h6>{t('Countryman')}</h6>
                  <p>
                    {t('Wearelookingcountryman')}
                  </p>
                </div>
                <div className="right-btn">
                  <Link to="/applyforcountrymanager">
                    {t('Applyonly')}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.66675 7.33337L14.1334 1.8667"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.6666 4.53325V1.33325H11.4666"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.33325 1.33325H5.99992C2.66659 1.33325 1.33325 2.66659 1.33325 5.99992V9.99992C1.33325 13.3333 2.66659 14.6666 5.99992 14.6666H9.99992C13.3333 14.6666 14.6666 13.3333 14.6666 9.99992V8.66658"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div> */}
              <div className="single-card">
                <div className="left-content">
                  <h6>{t('MarketingManager')}</h6>
                  <p>
                    {t('Marketingmanagerpara')}
                  </p>
                </div>
                <div className="right-btn">
                  <Link to="/applyformarketingmanager">
                    {t('Applyonly')}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.66675 7.33337L14.1334 1.8667"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.6666 4.53325V1.33325H11.4666"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.33325 1.33325H5.99992C2.66659 1.33325 1.33325 2.66659 1.33325 5.99992V9.99992C1.33325 13.3333 2.66659 14.6666 5.99992 14.6666H9.99992C13.3333 14.6666 14.6666 13.3333 14.6666 9.99992V8.66658"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Career;
