import React from 'react'
import Navbar from "../landing/header/Navbar";

const BannerTerms = () => {
    return (
        <>
            <Navbar />
            <section className="main-bannernew">
                <img
                    src="https://res.cloudinary.com/drt6vurtt/image/upload/v1709191268/gems/banner-bg_kk3bqp.svg"
                    alt="img"
                    className="img-fluid banner-bg"
                />
                <div className="custom-container">
                    <div className="main-contentss">
                        <div className="insuranceheads">
                            <h3>
                                Terms Of Use
                            </h3>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BannerTerms
