import React, { useState } from 'react'
import "./info.scss"
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap';

const Information = () => {
    const { t } = useTranslation()

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    return (
        <>
            <section className="information-section information-section-incentiv">
                <div className="first-para" style={{ margin: "0" }}>
                    <h2>{t('Gems-detailt11')} <span></span></h2>
                    <p>{t('Inc-para1')}</p>
                </div>
                {/* <div className="img-wrapper">
                    <img src="\assets\detail-page\projectinfoinc.png" alt="img" className='img-fluid' />
                </div> */}
                <div className="first-para">
                    <h2>{t('Inc-detailt13')}<span></span></h2>
                    <ul>
                        <li>{t('Inc-para2')}</li>
                        <li>{t('Inc-para3')}</li>
                        <li>{t('Inc-para4')}</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>{t('Inc-detailt24')} <span></span></h2>
                    <p>{t('Inc-para5')}</p>
                </div>
                <div className="first-para">
                    <p className='text-center'>{t('Formodetech')}, <a href="\incentiv-whitepaper-en.pdf" target='_blank' style={{ color: "#000", fontWeight: "600" }}>{t('ReadIncwhitepaper')}</a></p>
                </div>
                <div className="first-para">
                    <h2>{t('Dop-detail31')} <span></span></h2>
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img">
                            <img src="\assets\detail-page\inc-tokenmoics.png" alt="img" className='img-fluid' />
                        </div>
                    </div>

                </div>
                <div className="first-para">
                    <p className='text-center'>{t('Lookingformore')} <a href="\Inc-Foundataion-Paper(EN)-v1.1.pdf" target='_blank' style={{ color: "#000", fontWeight: "600" }}>{t('ReadIncFoundationpaper')}</a></p>
                </div>
                <div className="first-para">
                    <h2>{t('Distandallocation')} <span></span></h2>
                    <div onClick={() => { setShow1(true) }} className="twice-tokenomics" style={{ cursor: "pointer" }}>
                        <div className="tokenomic-img">
                            <img src="\assets\detail-page\incentiv-allocation.png" alt="img" className='img-fluid' />
                        </div>
                    </div>

                </div>
                <div className="first-para">
                    <p className='text-center'>{t('Fordetailinfo')} <a href="\Inc-Foundataion-Paper(EN)-v1.1.pdf" target='_blank' style={{ color: "#000", fontWeight: "600" }}>{t('Foundpaper')}</a></p>
                </div>
                <div className="first-para">
                    <h2>{t('Inc-detailt34')} <span></span></h2>
                    <div className="team-gems">
                        <div className="single-team">
                            <img src="\assets\detail-page\incteam\t1.webp" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team1h')}</h6>
                            <p>{t('Inc-team1p')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\detail-page\incteam\t2.jpg" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team2h')}</h6>
                            <p>{t('Inc-team2p')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\detail-page\incteam\t3.jpg" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team3h')}</h6>
                            <p>{t('Inc-team3p')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\detail-page\incteam\t4.jpg" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team4h')}</h6>
                            <p>{t('Inc-team4p')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\detail-page\incteam\t5.jpg" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team5h')}</h6>
                            <p>{t('Inc-team5p')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\detail-page\incteam\t6.jpg" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team6h')}</h6>
                            <p>{t('Inc-team6p')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\detail-page\incteam\t7.jpg" alt="img" className='img-fluid' />
                            <h6>{t('Inc-team7h')}</h6>
                            <p>{t('Inc-team7p')}</p>
                        </div>
                    </div>

                </div>
            </section>



            <Modal className='image-preview' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='p-0'>
                    <img src="\assets\detail-page\incentiv-allocation.png" alt="Selected" className="img-fluid w-100" />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Information
