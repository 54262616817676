import React from 'react'
import Bannerinsurance from './Bannerinsurance'
import './insurance.scss'

const Insurance = () => {
    return (
        <div className="wrapper-apply">
            <Bannerinsurance />
            <section className="insurance-section">
                <div className="custom-container">
                    <div className="insuranceinner">
                    <p className="toppara">
                    Gems offers an optional insurance feature to safeguard your investment in private token sales. This insurance provides peace of mind and financial protection in case the token's value drops after listing.
                    </p>
                    <h6 className="insurancehead">
                    How It Works
                    </h6>
                    <ul>
                        <li>Coverage: The insurance covers 70% of your investment.</li>
                        <li>Cost: 15% of your total investment amount.</li>
                        <li>Duration: Available for activation within the first week after token listing.</li>
                    </ul>
                    <p className="midpara">
                    <span className="midparabold">Example:</span> If you invest $100 in tokens, the insurance cost is $15 (15% of $100). The insurance covers $70 (70% of your investment).
                    </p>
                    <h6 className="insurancehead">
                    Insurance Breakdown
                    </h6>
                    <ul>
                        <li>7% is locked in a smart contract along with 70% of your purchased tokens.</li>
                        <li>8% goes to the insurance company for administrative costs.</li>
                    </ul>
                    <h6 className="insurancehead">
                    Token Distribution
                    </h6>
                    <ul>
                        <li>30% of your purchased tokens are available for immediate claim, once claim is open.</li>
                        <li>70% remain locked up until one week after the initial listing. </li>
                        <li>If insurance is activated, you receive an additional 7% on your invetsment.</li>
                    </ul>
                    <h6 className="insurancehead">
                    Activation Conditions
                    </h6>
                    <ol>
                        <li>The insurance can only be activated within the first week after initial token listing.</li>
                        <li>The insurance can only be activated if the token's current value (as per CoinMarketCap) is lower than your purchase price. </li>
                    </ol>
                    <h6 className="insurancehead">
                    Scenarios
                    </h6>
                    <h6 className="insurancehead" style={{ fontSize : '16px' }}>
                    1. Insurance Not Activated:
                    </h6>
                    <ul className='marginsetttt'>
                        <li>After one week, you receive the locked 70% of tokens plus an additional 7% (77% total).</li>
                        <li>Example: For a $100 investment, you get $77 worth of tokens at your original purchase price, plus the 30% claimed earlier.</li>
                    </ul>
                    <h6 className="insurancehead" style={{ fontSize : '16px' }}>
                    2. Insurance Activated:
                    </h6>
                    <ul>
                        <li>You receive USDT equivalent to 77% of your total investment.</li>
                        <li>Example: For a $100 investment, you keep the 30% of tokens claimed earlier and receive $77 in USDT.</li>
                    </ul>
                    <h6 className="insurancehead">
                    Key Points
                    </h6>
                    <ul>
                        <li>Insurance activation is optional.</li>
                        <li>Insurance can be activated within one week (7 days) of the initial token listing.</li>
                        <li>If not activated, you can claim all the tokens you purchased.</li>
                        <li>If activated, you get USDT compensation plus the initially claimed tokens.</li>
                    </ul>

                    <p className="lastpara">
                    This insurance feature allows you to participate in new token sales while minimizing your risk. It provides a safety net in case the token's value decreases, ensuring you can recover a significant portion of your investment.
                    </p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Insurance
