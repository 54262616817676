import React from 'react'
import "./latesttweet.scss"

const LatestTweet = () => {
    return (
        <>
            <section className="latest-tweet">
                <div className="first-para" style={{ margin: "0" }}>
                    <h2>Latest Tweets <span></span></h2>
                </div>
                <div className="bottom-tweets">
                    <div className="single-tweet">
                        <div className="upper-part">
                            <div className="profile">
                                <img src="\assets\detail-page\profile.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="text">
                                <h6>Gems_Official</h6>
                                <p>20 Nov, 2023</p>
                            </div>
                        </div>
                        <div className="bottom-text">
                            <p>Ut omnis suscipit aut exercitationem eius qui cumque ullam qui sunt nihil sit quam consequuntur non odio odio et corrupti velit. Est consequuntur quasi a blanditiis laboriosam et dicta fugit ea nihil earum.</p>
                        </div>
                        <div className="hashtags-text">
                            <h6>#FLOWX #Crypto #Blockchain #Token</h6>
                        </div>
                        <div className="img-part">
                            <img src="\assets\detail-page\dummy-tweet1.svg" alt="img" className='img-fluid' />
                        </div>
                    </div>
                    <div className="single-tweet">
                        <div className="upper-part">
                            <div className="profile">
                                <img src="\assets\detail-page\profile.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="text">
                                <h6>Gems_Official</h6>
                                <p>20 Nov, 2023</p>
                            </div>
                        </div>
                        <div className="bottom-text">
                            <p>Ut omnis suscipit aut exercitationem eius qui cumque ullam qui sunt nihil sit quam consequuntur non odio odio et corrupti velit. Est consequuntur quasi a blanditiis laboriosam et dicta fugit ea nihil earum.</p>
                        </div>
                        <div className="hashtags-text">
                            <h6>#FLOWX #Crypto #Blockchain #Token</h6>
                        </div>
                        <div className="img-part">
                            <img src="\assets\detail-page\dummy-tweet2png.png" alt="img" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LatestTweet
