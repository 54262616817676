import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'flag-icon-css/css/flag-icon.min.css'
import { Web3ReactProvider } from "@web3-react/core";
import { Provider } from 'react-redux';



const loadingMarkup = (
  <div className="py-4 text-center">
    <h3>Loading..</h3>
  </div>
)

ReactDOM.render(
    <Web3ReactProvider>
      <Suspense fallback={loadingMarkup}>
        <App />
      </Suspense>
    </Web3ReactProvider>,
  document.getElementById('root')
);











