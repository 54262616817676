import React from 'react'
import Navbar from "../landing/header/Navbar";

const Bannerinsurance = () => {
    return (
        <>
            <Navbar />
            <section className="main-bannernew">
                <img
                    src="https://res.cloudinary.com/drt6vurtt/image/upload/v1709191268/gems/banner-bg_kk3bqp.svg"
                    alt="img"
                    className="img-fluid banner-bg"
                />
                <div className="custom-container">
                    <div className="main-contentss">
                        <div className="insuranceheads">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="37" viewBox="0 0 36 37" fill="none">
                                    <path d="M27.8102 6.22005L19.5602 3.13005C18.7052 2.81505 17.3102 2.81505 16.4552 3.13005L8.2052 6.22005C6.6152 6.82005 5.3252 8.68005 5.3252 10.375V22.525C5.3252 23.74 6.1202 25.345 7.0952 26.065L15.3452 32.23C16.8002 33.325 19.1852 33.325 20.6402 32.23L28.8902 26.065C29.8652 25.33 30.6602 23.74 30.6602 22.525V10.375C30.6752 8.68005 29.3852 6.82005 27.8102 6.22005ZM23.2202 14.62L16.7702 21.07C16.5452 21.295 16.2602 21.4 15.9752 21.4C15.6902 21.4 15.4052 21.295 15.1802 21.07L12.7802 18.64C12.3452 18.205 12.3452 17.485 12.7802 17.05C13.2152 16.615 13.9352 16.615 14.3702 17.05L15.9902 18.67L21.6452 13.015C22.0802 12.58 22.8002 12.58 23.2352 13.015C23.6702 13.45 23.6702 14.185 23.2202 14.62Z" fill="#61FF00" />
                                </svg>
                                gems insurance
                            </span>
                            <h3>
                                Protect Your Investment
                            </h3>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Bannerinsurance
