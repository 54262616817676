import React, { useEffect, useState } from "react";
import BlogBanner from "./BlogBanner";
import "./blog.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

const Blog = () => {
  const [blogsData, setBlogsData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogs();
  }, []);

  const getBlogs = () => {
    var config = {
      method: "get",
      url: `https://blog.gems.vip/wp-json/wp/v2/posts?per_page=100`,
    };

    axios(config)
      .then(function (res) {
        console.log("blog data: ", res);
        setBlogsData(res?.data);
      })
      .catch(function (error) {});
  };

  function removePTags(htmlString) {
    return htmlString.replace(/<\/?p>/gi, "");
  }

  return (
    <>
      <div className="wrapper-apply wrapper-career">
        <BlogBanner />
        <section className="blog-section">
          <div className="custom-container">
            <div className="parent">
              {blogsData?.length > 0 ? (
                blogsData?.map((item, index) => {
                  const cleanText = removePTags(item?.title?.rendered);

                  return (
                    <>
                      <Link
                        to={{
                          pathname: `/blog/${item?.slug}`,
                          state: {
                            id: item?.id,
                          },
                        }}
                      >
                        <div className="single-card">
                          <div className="main-img">
                            <img
                              //   src="\assets\dummy\1.png"
                              src={item?.uagb_featured_image_src?.medium[0]}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                          <div className="bottom-text">
                            <p>{moment(item?.date).format("MMMM DD, YYYY")}</p>
                            <h6>{cleanText}</h6>
                          </div>
                        </div>
                      </Link>
                    </>
                  );
                })
              ) : (
                <></>
              )}
              {/* <div className="single-card">
                <div className="main-img">
                  <img
                    src="\assets\dummy\2.png"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <div className="bottom-text">
                  <p>July 8, 2024</p>
                  <h6>
                    Qui reiciendis nesciunt quo rerum vero ad animi rerum sit
                    saepe voluptatibus
                  </h6>
                </div>
              </div>
              <div className="single-card">
                <div className="main-img">
                  <img
                    src="\assets\dummy\3.png"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <div className="bottom-text">
                  <p>July 8, 2024</p>
                  <h6>
                    Qui reiciendis nesciunt quo rerum vero ad animi rerum sit
                    saepe voluptatibus
                  </h6>
                </div>
              </div>
              <div className="single-card">
                <div className="main-img">
                  <img
                    src="\assets\dummy\4.png"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <div className="bottom-text">
                  <p>July 8, 2024</p>
                  <h6>
                    Qui reiciendis nesciunt quo rerum vero ad animi rerum sit
                    saepe voluptatibus
                  </h6>
                </div>
              </div>
              <div className="single-card">
                <div className="main-img">
                  <img
                    src="\assets\dummy\5.png"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <div className="bottom-text">
                  <p>July 8, 2024</p>
                  <h6>
                    Qui reiciendis nesciunt quo rerum vero ad animi rerum sit
                    saepe voluptatibus
                  </h6>
                </div>
              </div>
              <div className="single-card">
                <div className="main-img">
                  <img
                    src="\assets\dummy\6.png"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <div className="bottom-text">
                  <p>July 8, 2024</p>
                  <h6>
                    Qui reiciendis nesciunt quo rerum vero ad animi rerum sit
                    saepe voluptatibus
                  </h6>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Blog;
